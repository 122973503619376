






























import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Inject } from "vue-property-decorator";
import { MysteryBoxViewModel } from "../viewmodels/mystery-box-viewmodel";

@Observer
@Component({
  components: {
    BoxInfo: () => import("@/modules/mystery-box/components/box-info.vue"),
  },
})
export default class OpenBoxDialog extends Vue {
  @Inject() vm!: MysteryBoxViewModel;
}
